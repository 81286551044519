@import "../../../styles/_chunk";

.blog-card-label-container,
.blog-card-label-container-games,
.blog-card-label-container-news,
.blog-card-label-container-develop,
.blog-card-label-container-software,
.blog-card-label-container-books,
.blog-card-label-container-community, {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  margin-top: -8px;
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media-breakpoint-only(md) {
    margin-top: -12px;
    margin-right: 7px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -6px;
    margin-right: 7px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 9px;
  }

  @include media-breakpoint-up(xxl) {
    margin-right: 11px;
  }

  .blog-card-label {
    padding: 4px 10px;
    padding-left: 0;
    color: $white;
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }

  .label-slash {
    width: 24px;
  }

  .label-triangle {
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
  }
}

.blog-card-label-container-games {
  .blog-card-label {
    background: $label-primary;
  }

  .label-slash {
    background: linear-gradient(66deg, transparent 0%, transparent 49%, $label-primary 51%, $label-primary 100%);
  }

  .label-triangle {
    border-top: 5px solid $label-primary-dark;
  }
}

.blog-card-label-container-news {
  .blog-card-label {
    background: $label-navy;
  }

  .label-slash {
    background: linear-gradient(66deg, transparent 0%, transparent 49%, $label-navy 51%, $label-navy 100%);
  }

  .label-triangle {
    border-top: 5px solid $label-navy-dark;
  }
}

.blog-card-label-container-develop {
  .blog-card-label {
    background: $label-green;
  }

  .label-slash {
    background: linear-gradient(66deg, transparent 0%, transparent 49%, $label-green 51%, $label-green 100%);
  }

  .label-triangle {
    border-top: 5px solid $label-green-dark;
  }
}

.blog-card-label-container-community,
.blog-card-label-container-books,
.blog-card-label-container-software {
  .blog-card-label {
    background: $label-purple;
  }

  .label-slash {
    background: linear-gradient(66deg, transparent 0%, transparent 49%, $label-purple 51%, $label-purple 100%);
  }

  .label-triangle {
    border-top: 5px solid $label-purple-dark;
  }
}
